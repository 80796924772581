import { walletStyle,changeType } from "@/utils/record"
import { fromSearchFore } from '@/const/publicData/record'
export const topUp_ = [
...fromSearchFore,
{
    type: 'input',
    name: '币种名称',
    prop: 'coin',
},{
    type: 'input',
    name: '钱包名称',
    prop: 'walletName',
},{
    type: 'input',
    name: '钱包地址',
    prop: 'walletAddress',
},{
    type: 'input',
    name: 'From',
    prop: 'fromAddress',
},{
    type: 'input',
    name: 'ID',
    prop: 'recordId',
},{
    type: 'datetime',
    name: '交易时间',
    prop: 'confirmTime',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'createTime',
},]
export const withdrawCash_ = [
...fromSearchFore,
{
    type: 'input',
    name: '币种名称',
    prop: 'coin',
},{
    type: 'input',
    name: '钱包名称',
    prop: 'walletName',
},{
    type: 'select',
    name: '审核状态',
    prop: 'status',
    postData: [{ label: '待审核', value: 0 },{label: '审核通过',value:1},{label: '审核拒绝',value:2},{label: '待录入',value:4},{label: '录入成功',value:5}],
},{
    type: 'input',
    name: '商户地址',
    prop: 'fromAddress',
},{
    type: 'input',
    name: 'To',
    prop: 'toAddress',
},{
    type: 'input',
    name: 'TXID',
    prop: 'txid',
},{
    type: 'input',
    name: 'ID',
    prop: 'applyId',
},{
    type: 'input',
    name: '交易ID',
    prop: 'transactionId',
},{
    type: 'datetime',
    name: '提现时间',
    prop: 'withdrawTime',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'applyTime',
},]
export const aggregation_ = [{
    type: 'input',
    name: 'ID',
    prop: 'collectionRecordId',
},
...fromSearchFore,
{
    type: 'input',
    name: '币种名称',
    prop: 'coin',
},{
    type: 'input',
    name: '钱包名称',
    prop: 'walletName',
},{
    type: 'input',
    name: '商户地址',
    prop: 'toAddress',
},{
    type: 'input',
    name: '客户地址',
    prop: 'fromAddress',
},{
    type: 'input',
    name: 'TXID',
    prop: 'txid',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'createTime',
},{
    type: 'datetime',
    name: '交易时间',
    prop: 'confirmTime',
},{
    type: 'select',
    name: '状态',
    prop: 'status',
    postData: [{ label: '待确认', value: 1 },{label: '已确认',value:2},{label: '确认失败',value:3}],
},]
export const walletWater_ = [{
    type: 'input',
    name: '变动ID',
    prop: 'changeLogId',
},
...fromSearchFore,
{
    type: 'input',
    name: '币种名称',
    prop: 'coin',
},{
    type: 'input',
    name: '钱包名称',
    prop: 'walletName',
},{
    type: 'select',
    name: '钱包类型',
    prop: 'walletType',
    postData: walletStyle,
},{
    type: 'input',
    name: 'From',
    prop: 'fromAddress',
},{
    type: 'input',
    name: 'To',
    prop: 'toAddress',
},{
    type: 'input',
    name: 'TXID',
    prop: 'txid',
},]
export const accountWater_ = [{
    type: 'input',
    name: '变动ID',
    prop: 'changeLogId',
},
...fromSearchFore,
{
    type: 'input',
    name: '币种名称',
    prop: 'coin',
},
{
    type: 'select',
    name: '业务类型',
    prop: 'relationType',
    postData: changeType,
    props:{
        value:'value',
        label:'label'
    },
},]
export const balanceWallet_ = [
...fromSearchFore,
{
    type: 'input',
    name: '币种名称',
    prop: 'coin',
},{
    type: 'input',
    name: '钱包名称',
    prop: 'walletName',
},{
    type: 'input',
    name: '钱包地址',
    prop: 'walletAddress',
},{
    type: 'datetime',
    name: '更新时间',
    prop: 'updateTime',
},]
export const balanceAccount_ = [
...fromSearchFore,
{
    type: 'input',
    name: '币种名称',
    prop: 'coin',
},
// {
//     type: 'input',
//     name: '钱包名称',
//     prop: 'walletName',
// },
// {
//     type: 'input',
//     name: '钱包地址',
//     prop: 'walletAddress',
// },{
//     type: 'input',
//     name: 'From',
//     prop: 'fromAddress',
// },
{
    type: 'datetime',
    name: '更新时间',
    prop: 'updateTime',
},]
export const accountTime_ = [
...fromSearchFore,
{
    type: 'input',
    name: '币种名称',
    prop: 'coin',
},{
    type: 'input',
    name: '钱包名称',
    prop: 'walletName',
},{
    type: 'input',
    name: '钱包地址',
    prop: 'walletAddress',
},{
    type: 'select',
    name: '订单类型',
    prop: 'relationType',
    postData: changeType,
},{
    type: 'datetime',
    name: '冻结时间',
    prop: 'unfreezeTime',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'createTime',
},]
import { changeType,walletStyle,relationType,frozenType} from "@/utils/record"
export const topUp = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 200,
        label: 'ID',
        prop: 'recordId',
    },{
        width: 100,
        label: '商户',
        prop: 'tenantName',
    }, {
        width: 100,
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:120,
        label: '币种',
        prop: 'coin'
    },{
        width:120,
        label: '钱包名称',
        prop: 'walletName'
    },  {
        width:200,
        label: '钱包地址',
        prop: 'walletAddress'
    },{
        width:120,
        label: '钱包类型',
        prop: 'walletType',
        dicData: walletStyle,
    },{
        width:200,
        label: 'From',
        prop: 'fromAddress'
    },{
        width:300,
        label: 'TXID',
        prop: 'txid'
    },{
        width:100,
        label: '数量',
        prop: 'amount'
    },{
        width:200,
        label: '链费',
        prop: 'txFee'
    },{
        width:180,
        label: '交易时间',
        prop: 'confirmTime'
    },{
        width:180,
        label: '创建时间',
        prop: 'createTime'
    },]
}
export const withdrawCash = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 200,
        label: 'ID',
        prop: 'applyId',
    },{
        width: 300,
        label: '交易ID',
        prop: 'transactionId',
    },{
        width:100,
        label: '审核状态',
        prop: 'status',
        dicData: [{ label: '待审核', value: 0 },{label: '审核通过',value:1},{label: '审核拒绝',value:2},{label: '待录入',value:4},{label: '录入成功',value:5}],
    },{
        width: 100,
        label: '商户',
        prop: 'tenantName',
    }, {
        width: 100,
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:120,
        label: '币种',
        prop: 'coin'
    }, {
        width:200,
        label: '商户地址',
        prop: 'fromAddress'
    },{
        width:120,
        label: '钱包名称',
        prop: 'fromWalletName'
    },{
        width:120,
        label: '钱包类型',
        prop: 'fromWalletType',
        dicData: [{ label: '出入金钱包', value: 1 },{label: '链费钱包',value:2}],
    },{
        width:200,
        label: 'To',
        prop: 'toAddress'
    },{
        width:100,
        label: '状态',
        prop: 'withdrawStatus',
        dicData: [{ label: '未处理', value: 0 },{label: '待确认',value:1},{label: '已确认',value:2},{label: '确认失败',value:3}],
    },{
        width:180,
        label: '交易时间',
        prop: 'withdrawTime'
    },{
        width:300,
        label: 'TXID',
        prop: 'txid'
    },{
        width:100,
        label: '数量',
        prop: 'amount'
    },{
        width:100,
        label: '链费',
        prop: 'txFee'
    },{
        width:200,
        label: '创建时间',
        prop: 'applyTime'
    }]
}
export const aggregation = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 200,
        label: 'ID',
        prop: 'collectionRecordId',
    },{
        width: 120,
        label: '商户',
        prop: 'tenantName',
    }, {
        width: 150,
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:120,
        label: '币种',
        prop: 'coin'
    }, {
        width:200,
        label: '商户地址',
        prop: 'toAddress'
    },{
        width:120,
        label: '钱包名称',
        prop: 'fromWalletName'
    },{
        width:120,
        label: '钱包类型',
        prop: 'fromWalletType',
        dicData: walletStyle,
    },{
        width:200,
        label: '客户ID',
        prop: 'userId'
    },{
        width:200,
        label: '客户地址',
        prop: 'fromAddress'
    },{
        width:300,
        label: 'TXID',
        prop: 'txid'
    },{
        width:100,
        label: '数量',
        prop: 'qty'
    },{
        width:100,
        label: '链费',
        prop: 'txFee'
    },{
        width:160,
        label: '交易时间',
        prop: 'confirmTime'
    },{
        width:160,
        label: '创建时间',
        prop: 'createTime'
    },{
        width:100,
        label: '状态',
        prop: 'status',
        dicData: [{ label: '未处理', value: 0 },{label: '待确认',value:1},{label: '已确认',value:2},{label: '确认失败',value:3}],
    },]
}
export const walletWater = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 200,
        label: '变动ID',
        prop: 'changeLogId',
    },{
        width: 120,
        label: '商户',
        prop: 'tenantName',
    }, {
        width: 100,
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:120,
        label: '币种',
        prop: 'coin'
    },{
        width:120,
        label: '钱包名称',
        prop: 'walletName'
    },{
        width:120,
        label: '钱包类型',
        prop: 'walletType',
        dicData: walletStyle,
    }, {
        width:200,
        label: '商户地址',
        prop: 'walletAddress'
    },{
        width:200,
        label: 'From',
        prop: 'fromAddress'
    },{
        width:200,
        label: 'To',
        prop: 'toAddress'
    },{
        width:300,
        label: 'TXID',
        prop: 'txid'
    },{
        width:100,
        label: '变动前数量',
        prop: 'beforeAmount'
    },{
        width:200,
        label: '变动数量',
        prop: 'amount'
    },{
        width:180,
        label: '变动后数量',
        prop: 'afterAmount'
    },{
        width:180,
        label: '操作类型',
        prop: 'changeType',
        dicData: changeType,
    },{
        width:180,
        label: '关联订单号',
        prop: 'relationId'
    },{
        width:180,
        label: '业务类型',
        prop: 'relationType',
        dicData: relationType,
    },]
}
export const accountWater = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 180,
        label: '变动ID',
        prop: 'changeLogId',
    },{
        width: 120,
        label: '商户',
        prop: 'tenantName',
    }, {
        width: 100,
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:120,
        label: '币种',
        prop: 'coin'
    },{
        width:100,
        label: '变动前数量',
        prop: 'beforeAmount'
    },{
        width:200,
        label: '变动数量',
        prop: 'amount'
    },{
        width:180,
        label: '变动后数量',
        prop: 'afterAmount'
    },{
        width:180,
        label: '操作类型',
        prop: 'changeType',
        dicData: changeType,
    },{
        width:180,
        label: '关联订单号',
        prop: 'relationId'
    },{
        width:180,
        label: '业务类型',
        prop: 'relationType',
        dicData: changeType,
    }]
}
export const balanceWallet = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 120,
        label: '商户',
        prop: 'tenantName',
    }, {
        width: 100,
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:120,
        label: '币种',
        prop: 'coin'
    },{
        width:120,
        label: '钱包名称',
        prop: 'walletName'
    },{
        width:120,
        label: '钱包类型',
        prop: 'walletType',
        dicData: walletStyle,
    },{
        label: '钱包地址',
        prop: 'walletAddress'
    },{
        width:100,
        label: '当前数量',
        prop: 'amount'
    },{
        width:180,
        label: '更新时间',
        prop: 'updateTime'
    },]
}
export const balanceAccount = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 120,
        label: '商户',
        prop: 'tenantName',
    }, {
        width: 150,
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:120,
        label: '币种',
        prop: 'coin'
    },{
        width:120,
        label: '当前可用数量',
        prop: 'amount'
    },{
        width:200,
        label: '冻结数量',
        prop: 'frozenAmount'
    },{
        label: '更新时间',
        prop: 'updateTime'
    },]
}
export const accountTime = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 100,
        label: 'ID',
        prop: 'frozenId',
    },{
        width: 120,
        label: '商户',
        prop: 'tenantName',
    }, {
        width: 150,
        label: 'App名称',
        prop: 'appName',
    }, {
        width:100,
        label: '链',
        prop: 'chain'
    }, {
        width:120,
        label: '协议',
        prop: 'protocol'
    },{
        width:120,
        label: '币种',
        prop: 'coin'
    },{
        width:120,
        label: '钱包名称',
        prop: 'walletName'
    },{
        width:200,
        label: '钱包地址',
        prop: 'walletAddress'
    },{
        width:120,
        label: '钱包类型',
        prop: 'walletType',
        dicData: walletStyle,
    },
    {
        width:200,
        label: '冻结数量',
        prop: 'amount'
    },{
        width:200,
        label: '订单号',
        prop: 'frozenId'
    },{
        width:100,
        label: '订单类型',
        prop: 'relationType',
        dicData: changeType,
    },{
        width:180,
        label: '解冻时间',
        prop: 'unfreezeTime'
    },{
        width:100,
        label: '冻结类型',
        prop: 'frozenType',
        dicData: frozenType,
    },{
        width:180,
        label: '创建时间',
        prop: 'createTime'
    },]
}